<template>
    <div class="new-page">
        <div v-if="typeStatus === -1 || typeStatus === -2">
            <div class="title-nav">
                <div class="o-img"><img src="../../assets/images/656.png" /></div>
                <div class="color_word">企业信息认证</div>
            </div>
            <div class="list-wrap">
                <div class="list-item">
                    <div class="label_name">申请人姓名</div>
                    <div class="val-input"><Input v-model="formItem.name" placeholder="请输入申请人姓名" style="width: 320px" />
                    </div>
                </div>
                <div class="list-item">
                    <div class="label_name">公司名称</div>
                    <div class="val-input"><Input v-model="formItem.company_name" placeholder="请输入公司名称" style="width: 320px" /></div>
                </div>
                <div class="list-item">
                    <div class="label_name">申请人联系方式</div>
                    <div class="val-input"><Input v-model="formItem.mobile" placeholder="请输入申请人联系方式" style="width: 320px" /></div>
                </div>
                <div class="list-item">
                    <div class="label_name">企业法人姓名</div>
                    <div class="val-input"><Input v-model="formItem.legal_person" placeholder="请输入企业法人姓名" style="width: 320px" /></div>
                </div>
                <div class="list-item">
                    <div class="label_name">企业营业执照副本照片</div>
                    <div v-if="formItem.license_pic">
                        <div class="license-pic">
                            <img :src="formItem.license_pic">
                        </div>
                    </div>
                    <div style="width: 320px;">
                        <Upload :show-upload-list="false" :headers='headers' :action="imageAction"
                            :on-format-error="handleFormatError" :format="['jpg', 'jpeg', 'png', 'doc', 'docx', 'pdf']"
                            :on-success="upload_success">
                            <Button type="success" ghost>选择文件</Button>
                        </Upload>
                    </div>
                </div>
                <div class="list-item">
                    <div class="label_name">企业营业执照注册号</div>
                    <div class="val-input"><Input v-model="formItem.license_register" placeholder="请输入企业营业执照注册号"
                            style="width: 320px" /></div>
                </div>
            </div>
            <div class="b-req" @click="submitItem">确认</div>
        </div>
        <div class="far-msg">
            <div class="else-wrap" v-if="typeStatus === 0 || typeStatus === 1 || typeStatus === 2">
                <div style="font-size: 16px;margin-bottom: 40px" v-if="typeStatus === 1"> <span style="color: #A7D07A">恭喜你，</span> 完成了身份认证</div>
                <div class="loading-img">
                    <img v-if="typeStatus === 2" src="../../assets/images/a-1.png" />
                    <img v-if="typeStatus === 1" src="../../assets/images/a-2.png" />
                    <img v-if="typeStatus === 0" src="../../assets/images/a-3.png" />
                    <img v-if="typeStatus === 5" src="../../assets/images/a-3.png" />
                </div>
                <div class="loading-title">{{ typeStatus === 1 ? '认证成功' : typeStatus === 0 ? '认证中' : '认证失败'}}</div>
                <div style="margin-top: 10px;font-size: 14px" v-if="typeStatus === 2">请返回<span
                        style="color: #1A73E8;cursor: pointer" @click="typeStatus = -1">重新修改></span></div>
                <div style="margin-top: 10px;font-size: 14px" v-if="typeStatus === 0">请稍等，我们会尽快完成信息审核</div>
                <div style="margin-top: 10px;font-size: 14px" v-if="typeStatus === 5">授权中</div>
            </div>
            <div class="right-del" v-if="typeStatus === 1">
                <div style="font-size: 15px;color: #555555;margin-bottom: 5px"><span
                        style="color:#A7A7A7;margin-right: 15px;display: inline-block;width: 150px;text-align: right">公司名称</span>
                        {{ moreInfo.company_name }}
                </div>
                <div style="font-size: 15px;color: #555555;margin-bottom: 5px"><span
                        style="color:#A7A7A7;margin-right: 15px;display: inline-block;width: 150px;text-align: right">企业法人名称</span>
                        {{ moreInfo.legal_person }}
                </div>
                <div style="font-size: 15px;color: #555555;margin-bottom: 5px"><span
                        style="color:#A7A7A7;margin-right: 15px;display: inline-block;width: 150px;text-align: right">申请人姓名</span>
                        {{ moreInfo.name }}
                </div>
                <div style="font-size: 15px;color: #555555;margin-bottom: 5px"><span
                        style="color:#A7A7A7;margin-right: 15px;display: inline-block;width: 150px;text-align: right">申请人联系方式</span>{{
                            moreInfo.mobile
                        }}</div>
                <div style="font-size: 15px;color: #555555;margin-bottom: 5px"><span
                        style="color:#A7A7A7;margin-right: 15px;display: inline-block;width: 150px;text-align: right">企业营业执照注册号</span>{{
                            moreInfo.company_id
                        }}</div>
                <Button type="success" style="margin-top: 10px;margin-left: 16px" @click="getPrivilegeLs">请求授权</Button>
            </div>
        </div>
        <Iframe ref="iframeDom" @submitIframe="submitIframe"></Iframe>
    </div>
</template>

<script>
import config from '@/config/index'
import { getCookies } from '@/utils/helper'
import { enterpriseAuth, authStatus, applyRelation, getPrivilege, relationStatus } from '../../api/interFace'
import Iframe from '../../components/iframe/index'
export
    default {
        name: "index",
        components: { Iframe },
        data() {
            return {
                formItem: {
                    license_register: '',
                    license_pic: '',
                    legal_person: '',
                    mobile: '',
                    company_name: '',
                    name: ''
                },
                typeStatus: '',
                needShow: false,
                imageAction: config.apiUrl + '/adminapi/common/uploads',
                headers: { 'Authori-zation': getCookies('token') },
                ifUrl: '',
                showWrite: false,
                moreInfo: {}
            }
        },
        created() {
            this.getStatus()
            this.getPad()
        },
        methods: {
            getPad() {
                relationStatus().then(res => {
                    console.log(res)
                })
            },
            getPrivilegeLs() {
                getPrivilege().then(res => {
                    window.open(res.data.pageUrl)
                })
            },
            getStatus() {
                authStatus().then(res => {
                    this.typeStatus = res.data.status
                    this.moreInfo = res.data
                    if (res.data.status === 3) {
                        this.$nextTick(() => {
                            this.$refs.iframeDom.show(res.data.pageUrl)
                        })
                    } else if (res.data.status === 4) {
                        this.$nextTick(() => {
                            this.$refs.iframeDom.show(res.data.pageUrl)
                        })
                    } else if (res.data.status === -1 || res.data.status === -2) {
                        this.$store.commit('userInfo/show_pop', false)
                    }
                }).catch(res => {
                    this.$Message.error(res.msg)
                })
            },
            submitIframe() {
                this.getStatus()
            },
            // 文件类型上传失败
            handleFormatError() {
                this.$Message.warning('暂不支持上传此类型文件')
            },
            // 判断类型上传文件
            upload_success(e) {
                this.formItem.license_pic = e.data.src
            },
            submitItem() {
                let _this = this
                enterpriseAuth(_this.formItem).then(async res => {
                    if (res.data.status === 203) {
                        if (res.data.relation_uid) {
                            this.$Modal.confirm({
                                title: '关联提示',
                                content: '请问您是否确认关联此项企业认证！！！',
                                onOk: () => {
                                    _this.formItem.relation_uid = res.data.relation_uid
                                    _this.formItem.relation_store_id = res.data.relation_store_id
                                    applyRelation(_this.formItem).then(res => {
                                        _this.getPad()
                                        _this.getStatus()
                                        _this.$Message.success(res.msg)
                                    }).catch(res => {
                                        _this.$Message.error(res.msg)
                                    })
                                },
                                onCancel: () => {
                                }
                            });
                        }
                        return
                    }
                    if (res.data.status === 204) {
                        window.open(res.data.url.pageUrl)
                        console.log('授权链接')
                        return
                    }
                    this.$nextTick(() => {
                        _this.$refs.iframeDom.show(res.data.pageUrl)
                    })
                    _this.$Message.success(res.msg)
                }).catch(res => {
                    _this.$Message.error(res.msg)

                })
            },
        }
    }
</script>

<style scoped>
.new-page {
    width: 100%;
    height: 82vh;
    background: white;
    margin-top: 20px;
    padding: 20px 30px;
    box-sizing: border-box;
    position: relative;
    display: flex;
}

.o-img {
    width: 22.4px;
    height: 25.2px;
}

.o-img img {
    width: 100%;
    height: 100%;
}

.title-nav {
    display: flex;
    align-items: center;
}

.color_word {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    margin-left: 8px;
}

.label_name {
    font-size: 14px;
    color: #676767;
    margin-bottom: 14px;
}

.list-wrap {
    margin-top: 10px;
    width: 800px;
    display: flex;
    flex-wrap: wrap;
}

.list-item {
    flex: 1;
    margin-top: 40px;
}

.b-req {
    width: 300px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #1A73E8;
    color: white;
    font-size: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    cursor: pointer;
}

.b-req:hover {
    opacity: 0.8;
}

.license-pic {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
}

.license-pic img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
}

.loading-img {
    width: 100px;
    height: 100px;
}

.loading-img img {
    width: 100%;
    height: 100%;
}

.loading-title {
    margin-top: 20px;
    font-size: 15px;
    color: #474747;
}

.else-wrap {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: column;
    /*margin-top: 240px;*/
}

.right-del {
    margin-top: 50px;
    padding-left: 40px;
    border-left: 2px solid #999999;
    height: 120px;
}

.far-msg {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 45%;
    top: 200px;
    width: 600px;
    transform: translateX(-50%);
    z-index: 5;
}
</style>
